import React from 'react';
import { Link } from 'react-router-dom';
import {
  getPrice,
  currency,
  computeSubtotal,
  getItemsCount,
} from '../utils/utilities';

const containerNameMap = (() => {
  let nameMap = new Map();
  nameMap.set('flat', 'Flat');
  nameMap.set('hb', 'Hanging Basket');
  nameMap.set('combo hb', 'Combo HB');
  nameMap.set('accent 4 inch', '4 inch pot');
  nameMap.set('herb 4 inch', '4 inch pot');
  nameMap.set('tomato 4 inch', '4 inch pot');
  nameMap.set('flower 4.5 inch', '4.5 inch pot');
  nameMap.set('flower 6 inch', '6 inch pot');
  nameMap.set('grass gallon', '1 gallon pot');
  nameMap.set('tomato gallon', '1 gallon pot');
  nameMap.set('patio pot', '12 inch patio');
  return nameMap;
})();

const totals = (cart) => {
  let qtySum = 0;
  let subTotal = 0;
  if (cart.length) {
    qtySum = getItemsCount(cart);
    subTotal = (computeSubtotal(cart) / 100).toFixed(2);
  }
  return [qtySum, subTotal];
};
function Hero() {
  return (
    <div className='flex items-center flex-cols'>
      {/* wide screen - bold */}
      <p className='hidden text-xl font-semibold print:hidden sm:block '>
        Boy Scout Troop 34 - 2022 Spring Plant Sale
      </p>
      {/* wide screen - print - no bold */}
      <p className='hidden text-xl print:block'>
        Boy Scout Troop 34 - 2022 Spring Plant Sale
      </p>
      {/* mobile screen - bold */}
      <div className='flex flex-col items-center sm:hidden print:hidden'>
        <p className='mb-2 text-xl font-semibold'>Boy Scout Troop 34</p>
        <p className='mb-2 text-xl font-semibold'>Spring Plant Sale</p>
        <p className='mb-2 text-xl font-semibold'>2022</p>
      </div>
    </div>
  );
}
function Header() {
  return (
    <>
      {/* wide screen & print */}
      <div className='flex-col items-center hidden px-6 py-4 mb-12 sm:flex print:flex'>
        <Hero />
        <p>Orders and Payments are due no later than Monday, March 7th.</p>
        <p>
          Print this page or attach to an email and return it to your scout.
        </p>
        <p>Plants will be delivered on Friday, May 6th.</p>
        <p>Please make checks payable to Troop 34.</p>
        <p>(no tax, we are non-profit organization)</p>
      </div>
      {/* mobile */}
      <div className='flex flex-col items-center sm:hidden print:hidden'>
        <Hero />
        {/* Info  */}
        <p>Orders and Payments are due no</p>
        <p>later than Monday, March 7th.</p>
        <p>Print this page or attach to an email</p>{' '}
        <p>and return it to your scout.</p>
        <p>Plants will be delivered on Friday, May 6th.</p>
        <p>Please make checks payable to Troop 34.</p>
        <p>(no tax, we are a non-profit organization)</p>
      </div>
    </>
  );
}

function OrderedFlowers({ cart }) {
  const orderedFlowers = cart.map((flower, id) => {
    let container = containerNameMap.get(flower.container);
    return (
      <div className='grid my-2 text-xs grid-cols-order-xxs print:grid-cols-order print:text-base xs:grid-cols-order xs:text-base'>
        <p className='text-center'>{flower.quantity}</p>
        <p className='px-1 xs:px-0'>{flower.name}</p>
        <p className='px-1 xs:px-0'>{flower.variety}</p>
        <p className='px-1 xs:px-0'>{container}</p>
        <p className='px-1 text-right xs:px-0'>
          {currency(getPrice(flower.container) * flower.quantity)}
        </p>
      </div>
    );
  });
  return <div>{orderedFlowers}</div>;
}
function OrderedFlowersHeader() {
  return (
    <>
      {/* screen */}
      <div className='grid text-xs font-semibold text-left border-2 border-gray-50 grid-cols-order-xxs xs:grid-cols-order xs:text-base print:hidden'>
        <p className='text-center'>Qty</p>
        <p className='px-1 xs:px-0'>Flower</p>
        <p className='px-1 xs:px-0'>Variety</p>
        <p className='px-1 xs:px-0'>Container</p>
        <p className='px-1 text-right xs:px-0'>Price</p>
      </div>
      {/* print - no bold */}
      <div className='hidden text-left border-2 print:grid grid-cols-order border-gray-50'>
        <p className='text-center'>Qty</p>
        <p>Flower</p>
        <p>Variety</p>
        <p>Container</p>
        <p className='text-right'>Price</p>
      </div>
    </>
  );
}
function OrderedFlowersSummary({ sumOfItems, subTotal }) {
  return (
    <>
      {/* screen */}
      <div className='grid text-xs text-left border-2 border-t-4 border-gray-50 grid-cols-order-xxs xs:grid-cols-order xs:text-base print:hidden '>
        <p className='px-1 font-semibold text-center xs:px-0'>{sumOfItems}</p>
        <p></p>
        <p></p>
        <p></p>
        <p className='px-1 font-semibold text-right xs:px-0'>${subTotal}</p>
      </div>
      {/* print - no semibold */}
      <div className='hidden text-left border-2 border-t-4 print:grid grid-cols-order border-gray-50'>
        <p className='text-center'>${sumOfItems}</p>
        <p></p>
        <p></p>
        <p></p>
        <p className='text-right'>{subTotal}</p>
      </div>
    </>
  );
}

function Flowers({ cart }) {
  const [sumOfItems, subTotal] = totals(cart);
  return (
    <div className='flex flex-col items-center pr-2'>
      <OrderedFlowersHeader />
      {/* flowers */}
      <div className='border-2 border-gray-50'>
        <OrderedFlowers cart={cart} />
      </div>
      <OrderedFlowersSummary sumOfItems={sumOfItems} subTotal={subTotal} />
    </div>
  );
}

function TableRow({ label, value, value2 = '' }) {
  return (
    <tr className='h-10'>
      <td className='font-semibold w-36'>{label}</td>
      <td>
        {value} {value2}
      </td>
    </tr>
  );
}
function User({ user }) {
  return (
    <div className='flex flex-col items-center mb-12'>
      <div className='p-2 mb-2 border-2 border-gray-50'>
        {/* printer  */}
        <div className='hidden print:block'>
          <div className='grid grid-rows-3 grid-cols-user'>
            <div>Name: </div>
            <div>
              {user.first} {user.last}
            </div>
            <div>Tel: </div>
            <div>{user.tel}</div>
            <div>Email: </div>
            <div className='col-span-3'>{user.email}</div>
            <div>Address: </div>
            <div className='col-span-3'>{user.address}</div>
            <div>Scout: </div>
            <div className='col-span-3'>{user.scout}</div>
          </div>
        </div>
        {/* screen */}
        <table className='w-200 print:hidden'>
          <tbody>
            <TableRow label='Name' value={user.first} value2={user.last} />
            <TableRow label='Email' value={user.email} />
            <TableRow label='Address' value={user.address} />
            <TableRow label='Telephone' value={user.tel} />
            <TableRow label='Scout' value={user.scout} />
          </tbody>
        </table>
      </div>
      <Link to={'/userContact'} className='editLink'>
        <button className='px-3 py-1 mb-8 border-2 border-purple-100 rounded hover:bg-purple-200 print:hidden'>
          Edit
        </button>
      </Link>
    </div>
  );
}

const DisplayOrder = ({ cart, user }) => {
  return (
    <div className='flex flex-col items-center mb-12'>
      <Header />
      <User user={user} />
      <Flowers cart={cart} />
    </div>
  );
};

// export { DisplayOrder };
export default DisplayOrder;
