export const FLOWERS = [
  {
    id: 0,
    name: 'Alyssum',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/alyssumMixed512x138.jpg' },
      { name: 'White', image: '/assets/images/flats/alyssumWhite512x384.jpg' },
      {
        name: 'Purple',
        image: '/assets/images/flats/allysumPurple512x384.jpg',
      },
    ],
    description:
      'Lorem ipsum dolor dolor dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
  },
  {
    id: 1,
    name: 'Begonia',
    container: 'flat',
    sun: 'part shade',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/begoniaMixed512x384.jpg' },
      { name: 'White', image: '/assets/images/flats/begoniaWhite512x384.jpg' },
      { name: 'Pink', image: '/assets/images/flats/begoniaPink512x384.jpg' },
      { name: 'Red', image: '/assets/images/flats/begoniaRed512x384.jpg' },
    ],
  },
  {
    id: 2,
    name: 'Coleus',
    container: 'flat',
    sun: 'shade',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      {
        name: 'Mixed',
        image: '/assets/images/flats/coleusWizardMix512x384.jpg',
      },
    ],
  },
  {
    id: 3,
    name: 'Dahlia',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/dahliaMixed512x384.jpg' },
    ],
  },
  {
    id: 4,
    name: 'Dianthus',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/dianthusMixed512x384.jpg' },
    ],
  },
  {
    id: 5,
    name: 'Dusty Miller',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'White', image: '/assets/images/flats/dustyMiller512x384.jpg' },
    ],
  },
  {
    id: 6,
    name: 'Gazania',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      {
        name: 'Mixed',
        image: '/assets/images/flats/gazaniaMixed512x386.jpg',
      },
    ],
  },
  {
    id: 7,
    name: 'Impatiens',
    container: 'flat',
    sun: 'shade',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      {
        name: 'Dazzler mixed',
        image: '/assets/images/flats/impatiensPastelMix512x384.jpg',
      },
      {
        name: 'White',
        image: '/assets/images/flats/impatiensWhite512x384.jpg',
      },
      { name: 'Red', image: '/assets/images/flats/impatiensRed512x384.jpg' },
      {
        name: 'Salmom',
        image: '/assets/images/flats/impatiensSalmon512x384.jpg',
      },
      {
        name: 'Violet',
        image: '/assets/images/flats/impatiensViolet512x384.jpg',
      },
      {
        name: 'Hot pink',
        image: '/assets/images/flats/impatiensPink512x384.jpg',
      },
    ],
  },
  {
    id: 8,
    name: 'Marigold',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      {
        name: 'Bi-color',
        image: '/assets/images/flats/marigoldMixed512x384.jpg',
      },
      {
        name: 'Yellow',
        image: '/assets/images/flats/marigoldYellow512x384.jpg',
      },
      {
        name: 'Mixed',
        image: '/assets/images/shared/wildflowerDrawing512x384.jpg',
      },
    ],
  },
  {
    id: 9,
    name: 'Moss Rose',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/mossRoseMixed512x384.jpg' },
    ],
  },
  {
    id: 10,
    name: 'Pansy',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/pansy512x384.jpg' },
    ],
  },
  {
    id: 11,
    name: 'Petunia',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/petuniaMixed512x384.jpg' },
      {
        name: 'Midnight blue',
        image: '/assets/images/flats/petuniaMidnight512x384.jpg',
      },
      { name: 'Pink', image: '/assets/images/flats/petuniaPink512x384.jpg' },
      { name: 'Red', image: '/assets/images/flats/petuniaRed512x384.jpg' },
      { name: 'White', image: '/assets/images/flats/petuniaWhite512x384.jpg' },
      {
        name: 'Yellow',
        image: '/assets/images/flats/petuniaYellow512x384.jpg',
      },
    ],
  },
  {
    id: 12,
    name: 'Salvia',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      {
        name: 'Victoria blue',
        image: '/assets/images/flats/salviaVictoriaBlue512x384.jpg',
      },
      { name: 'Red', image: '/assets/images/flats/salviaRed512x384.jpg' },
    ],
  },
  {
    id: 13,
    name: 'Snap Dragons',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      {
        name: 'Dwarf mixed',
        image: '/assets/images/flats/snapDragonMix512x384.jpg',
      },
      {
        name: 'Tall mixed',
        image: '/assets/images/flats/snapDragonsTall512x384.jpg',
      },
    ],
  },
  {
    id: 14,
    name: 'Vinca',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Mixed', image: '/assets/images/flats/vincaMixed512x384.jpg' },
      {
        name: 'Apricot',
        image: '/assets/images/flats/vincaApricot512x384.jpg',
      },
      { name: 'Red', image: '/assets/images/flats/vincaRed512x384.jpg' },
      { name: 'White', image: '/assets/images/flats/vincaWhite512x384.jpg' },
      {
        name: 'Lavender',
        image: '/assets/images/flats/vincaLavender512x384.jpg',
      },
    ],
  },
  {
    id: 15,
    name: 'Viola',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      {
        name: 'Mixed',
        image: '/assets/images/flats/violaMixed512x384.jpg',
      },
    ],
  },
  {
    id: 16,
    name: 'Zinnia',
    container: 'flat',
    sun: 'full sun',
    group: 'flat',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    variety: [
      { name: 'Cherry', image: '/assets/images/flats/zinniaCherry512x383.jpg' },
      { name: 'Mixed', image: '/assets/images/flats/zinniaMixed512x384.jpg' },
    ],
  },
  {
    id: 20,
    name: 'Combo Basket',
    container: 'combo hb',
    sun: 'full sun',
    group: 'hb',
    description: 'Lorem ipsum',
    variety: [
      {
        name: 'Blue purple',
        image: '/assets/images/hb/comboBluePurple512x384.jpg',
      },
      {
        name: 'Pink and colors',
        image: '/assets/images/hb/comboPinkComp512x384.jpg',
      },
      {
        name: 'Red and colors',
        image: '/assets/images/hb/comboRedWhiteBlue512x384.jpg',
      },
      {
        name: 'Yellow and colors',
        image: '/assets/images/hb/comboRedYellowBlue512x384.jpg',
      },
    ],
  },
  {
    id: 21,
    name: 'Dbl Rosebud Impatiens',
    container: 'hb',
    sun: 'filtered sun',
    group: 'hb',
    description: 'Lorem ipsum',
    variety: [
      {
        name: 'Sparkler pink',
        image: '/assets/images/hb/dblRosebudSparklerHotPinkHB512x384.jpg',
      },
    ],
  },
  {
    id: 22,
    name: 'Dragon Wing Begonia',
    container: 'hb',
    sun: 'part shade',
    group: 'hb',
    variety: [
      {
        name: 'Red',
        image: '/assets/images/hb/dragonWingBegoniaRed512x384.jpg',
      },
    ],
  },
  {
    id: 23,
    name: 'Fuschia',
    container: 'hb',
    sun: 'full sun',
    group: 'hb',
    description: 'Lorem ipsum',
    variety: [
      {
        name: 'Red purple',
        image: '/assets/images/hb/fuschiaRedPurple512x384.jpg',
      },
      {
        name: 'Red white',
        image: '/assets/images/hb/fuschiaRedWhite512x384.jpg',
      },
    ],
  },
  {
    id: 24,
    name: 'Impatiens',
    container: 'hb',
    sun: 'shade',
    group: 'hb',
    description: 'Lorem ipsum',
    variety: [
      { name: 'Mixed', image: '/assets/images/hb/impatiensMixedHB512x384.jpg' },
      { name: 'Pink', image: '/assets/images/hb/impatiensPinkHB512x384.jpg' },
      { name: 'Red', image: '/assets/images/hb/impatiensRed512x384.jpg' },
    ],
  },
  {
    id: 25,
    name: 'Ivy Geranium',
    container: 'hb',
    sun: 'full sun',
    group: 'hb',
    description: 'Lorem ipsum',
    variety: [
      { name: 'Red', image: '/assets/images/hb/ivyGeraniumRedHB512x384.jpg' },
      { name: 'Pink', image: '/assets/images/hb/ivyGeraniumPinkHB512x384.jpg' },
      {
        name: 'Lavender',
        image: '/assets/images/hb/ivyGeraniumLavenderHB512x384.jpg',
      },
    ],
  },
  {
    id: 26,
    name: 'Million Bells',
    container: 'hb',
    sun: 'full sun',
    group: 'hb',
    description: 'Lorem ipsum',
    variety: [
      {
        name: 'Blue',
        image: '/assets/images/hb/millionBellsBlueHB512x384.jpg',
      },
      {
        name: 'Pink',
        image: '/assets/images/hb/millionBellsPinkHB512x384.jpg',
      },
      { name: 'Red', image: '/assets/images/hb/millionBellsRedHB512x384.jpg' },
      {
        name: 'Yellow',
        image: '/assets/images/hb/millionBellsYellowHB512x384.jpg',
      },
    ],
  },
  {
    id: 27,
    name: 'Petunia',
    container: 'hb',
    sun: 'full sun',
    group: 'hb',
    description: 'Lorem ipsum',
    variety: [
      {
        name: 'Bordeaux',
        image: '/assets/images/hb/petuniaBordeauxHB512x384.jpg',
      },
      {
        name: 'Burgundy star',
        image: '/assets/images/hb/petuniaBurgundyStarHB512x384.jpg',
      },
      {
        name: 'Midnight blue',
        image: '/assets/images/hb/petuniaMidnightBlueHB512x384.jpg',
      },
      { name: 'Pink', image: '/assets/images/hb/petuniaPinkHB512x384.jpg' },
      { name: 'Purple', image: '/assets/images/hb/petuniaPurpleHB512x384.jpg' },
      {
        name: 'Yellow',
        image: '/assets/images/shared/wildflowerDrawing512x384.jpg',
      },
    ],
  },
  {
    id: 40,
    name: 'Better Boy',
    container: 'tomato 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'The crown jewel of any home garden',
    variety: [
      { name: 'Tomato', image: '/assets/images/pots/betterBoy512x384.jpg' },
    ],
  },
  {
    id: 41,
    name: 'Cherry',
    container: 'tomato 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'The crown jewel of any home garden',
    variety: [
      { name: 'Tomato', image: '/assets/images/pots/cherry512x384.jpg' },
    ],
  },
  {
    id: 42,
    name: 'Early Girl',
    container: 'tomato 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'The crown jewel of any home garden',
    variety: [
      { name: 'Tomato', image: '/assets/images/pots/earlyGirl512x384.jpg' },
    ],
  },
  {
    id: 44,
    name: 'Roma',
    container: 'tomato 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'The crown jewel of any home garden',
    variety: [{ name: 'Tomato', image: '/assets/images/pots/roma512x384.jpg' }],
  },
  {
    id: 45,
    name: 'Super Steak',
    container: 'tomato 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'The crown jewel of any home garden',
    variety: [
      { name: 'Tomato', image: '/assets/images/pots/superSteak512x384.jpg' },
    ],
  },
  {
    id: 46,
    name: 'Gallon Patio Cherry',
    container: 'tomato gallon',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'One gallon patio pot',
    variety: [
      { name: 'Tomato', image: '/assets/images/pots/tomatoGallon512x384.jpg' },
    ],
  },
  {
    id: 47,
    name: 'Gallon Patio Tomato',
    container: 'tomato gallon',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'One gallon patio pot',
    variety: [
      {
        name: 'Tomato',
        image: '/assets/images/pots/tomatoGallonPlantSale512x384.jpg',
      },
    ],
  },
  {
    id: 50,
    name: 'Cucumber',
    container: 'vegetable 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh vegetables grown in your own garden',
    variety: [
      { name: 'Vegetable', image: '/assets/images/pots/cucumber512x384.jpg' },
    ],
  },
  {
    id: 51,
    name: 'Green Pepper',
    container: 'vegetable 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh vegetables grown in your own garden',
    variety: [
      {
        name: 'Vegetable',
        image: '/assets/images/pots/greenPepper512x384.jpg',
      },
    ],
  },
  {
    id: 52,
    name: 'Zucchini',
    container: 'vegetable 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh vegetables grown in your own garden',
    variety: [
      { name: 'Vegetable', image: '/assets/images/pots/zucchini512x384.jpg' },
    ],
  },
  {
    id: 60,
    name: 'Basil',
    container: 'herb 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh herbs grown in your own garden is heaven on earth',
    variety: [{ name: 'Herb', image: '/assets/images/pots/basil512x384.jpg' }],
  },
  {
    id: 61,
    name: 'Chives',
    container: 'herb 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh herbs grown in your own garden is heaven on earth',
    variety: [{ name: 'Herb', image: '/assets/images/pots/chives512x384.jpg' }],
  },
  {
    id: 62,
    name: 'Cilantro',
    container: 'herb 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh herbs grown in your own garden is heaven on earth',
    variety: [
      { name: 'Herb', image: '/assets/images/pots/cilantro512x384.jpg' },
    ],
  },
  {
    id: 63,
    name: 'Oregano',
    container: 'herb 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh herbs grown in your own garden is heaven on earth',
    variety: [
      { name: 'Herb', image: '/assets/images/pots/oregano512x384.jpg' },
    ],
  },
  {
    id: 64,
    name: 'Parsley',
    container: 'herb 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh herbs grown in your own garden is heaven on earth',
    variety: [
      { name: 'Herb', image: '/assets/images/pots/parsley512x384.jpg' },
    ],
  },
  {
    id: 65,
    name: 'Thyme',
    container: 'herb 4 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh herbs grown in your own garden is heaven on earth',
    variety: [{ name: 'Herb', image: '/assets/images/pots/thyme512x384.jpg' }],
  },
  {
    id: 67,
    name: 'Combo Herb Container',
    container: 'herb 12 inch',
    sun: 'full sun',
    group: 'herbTomato',
    description: 'Fresh herbs grown in your own garden is heaven on earth',
    variety: [
      {
        name: 'Basil•Parsley•Chives•Oregano',
        image: '/assets/images/pots/herbsCilantroChivesOregano512x384.jpg',
      },
    ],
  },
  {
    id: 70,
    name: 'Geranium',
    container: 'flower 4.5 inch',
    sun: 'full sun',
    group: 'pot',
    description: 'red, white, pink, salmon, violet/fuschia',
    variety: [
      { name: 'Red', image: '/assets/images/hb/ivyGeraniumRedHB512x384.jpg' },
      { name: 'Pink', image: '/assets/images/pots/geraniumPink512x384.jpg' },
      {
        name: 'Salmon',
        image: '/assets/images/hb/ivyGeraniumSalmonHB512x384.jpg',
      },
      {
        name: 'Violet/fuschia',
        image: '/assets/images/pots/geraniumVioletFuschia512x384.jpg',
      },
    ],
  },
  {
    id: 71,
    name: 'Purple Fountain Grass',
    container: 'grass gallon',
    sun: 'full sun',
    group: 'pot',
    description:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis, odit?',
    variety: [
      {
        name: 'Purple',
        image: '/assets/images/pots/fountainGrassPurple512x384.jpg',
      },
    ],
  },
  {
    id: 72,
    name: 'Patio Pots',
    container: 'patio pot',
    sun: 'full sun',
    group: 'pot',
    description: 'Geraniums, Spikes and Two Trailing Proven Winners',
    variety: [
      {
        name: 'Salmon',
        image: '/assets/images/pots/patioPotSalmon512x384.jpg',
      },
      { name: 'Pink', image: '/assets/images/pots/patioPotPink512x384.jpg' },
      { name: 'Red', image: '/assets/images/pots/patioPotRed512x384.jpg' },
      {
        name: 'Violet',
        image: '/assets/images/pots/patioPotViolet512x384.jpg',
      },
    ],
  },
  {
    id: 73,
    name: 'Sweet Potato Vine',
    container: 'accent 4 inch',
    sun: 'full sun',
    group: 'pot',
    description: 'Foilage plants to add interest to your garden',
    variety: [
      { name: 'Blackie', image: '/assets/images/pots/potatoVine512x384.jpg' },
      { name: 'Lime', image: '/assets/images/pots/potatoVineLime512x384.jpg' },
    ],
  },
  {
    id: 74,
    name: 'Vinca Vine',
    container: 'accent 4 inch',
    sun: 'full sun',
    group: 'pot',
    description: 'Foilage plants to accent your flowering plants',
    variety: [
      {
        name: 'Accent plant',
        image: '/assets/images/pots/vincaVine512x384.jpg',
      },
    ],
  },
  {
    id: 75,
    name: 'Spikes',
    container: 'accent 4 inch',
    sun: 'full sun',
    group: 'pot',
    description: 'Foilage plants to accent your flowering plants',
    variety: [
      { name: 'Accent plant', image: '/assets/images/pots/spikes512x384.jpg' },
    ],
  },
];
